import { Button, ButtonProps } from "@mui/material";

export type AppTextButtonProps = ButtonProps;

export const AppTextButton = ({
  className,
  color,
  onClick,
  startIcon,
  disabled,
  children,
  sx,
}: AppTextButtonProps) => {
  return (
    <Button
      sx={{
        borderRadius: "1.5rem",
        padding: "0 1.5rem",
        height: "2.5rem",
        textTransform: "none",
        ...sx,
      }}
      className={className}
      variant="text"
      onClick={onClick}
      startIcon={startIcon}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};
