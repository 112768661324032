import React from "react";
import { useSnackbarContext } from "./SnackbarProvider";

export const SnackbarWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { snackbar } = useSnackbarContext();

  return (
    <>
      {snackbar}
      {children}
    </>
  );
};
