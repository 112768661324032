import SnackbarMaterial, { SnackbarProps } from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { TextButton } from "../buttons/material/textButton/TextButton";
import { useSnackbarContext } from "./SnackbarProvider";

export const Snackbar = (props: SnackbarProps) => {
  const globalRedux = useSelector((state: RootState) => state.global);
  const dispatch = useDispatch();
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors,
  );
  const { setSnackbar } = useSnackbarContext();

  const handleClose = () => setSnackbar(null);

  const handleAction = (
    <>
      <TextButton text="Close" onClick={handleClose} />
    </>
  );

  return (
    <SnackbarMaterial
      onClose={handleClose}
      action={handleAction}
      open={true}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: componentColors.surface.surfContainer,
          color: componentColors.background.onBackground,
        },
        ...props.sx,
      }}
      {...props}
    />
  );
};
