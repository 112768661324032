import login from "../api/user";
import {IUserData} from "../interfaces/user/IUserData";
import {fetcher} from "./fetcher";
import * as Sentry from "@sentry/nextjs";

export async function userLogin(
    email: string,
    password: string
): Promise<IUserData> {
    return login(email, password)
        .then((user: IUserData) => {
            if (user?.role === "admin" && !user.statusCode) {
                throw new Error("Admins can't sign in");
            } else if (user?.role === "coach" && !user.statusCode) {
                const error = new Error(
                    "You can log in with this coach account at coach.pod1um.com"
                );
                throw error;
            } else if (user?.token) {
                return saveUserOnLocalStorage(user);
            } else if (!user.token) {
                throw new Error("Wrong Email or Password");
            }
        })
        .catch((e: Error) => {
            throw e;
        }) as Promise<IUserData>;
}

export async function loginWithMidias(
    token: string,
    provider: string,
    web = false
) {
    try {
        const user: IUserData = (await fetcher(
            `auth/provider?role=athlete&provider=${provider}&token=${token}${
                web ? "&web=true" : ""
            }`,
            {
                method: "POST",
            }
        )) as IUserData;

        if (user.role === "coach") {
            throw new Error(
                "You can log in with this coach account at coach.pod1um.com"
            );
        }

        return saveUserOnLocalStorage(user);
    } catch (e: unknown) {
        Sentry.captureException({
            category: "authentication",
            message: "error on loginWithMidias",
            data: e,
        });
        throw e;
    }
}

export function setCookie(name: string, value: string) {
    document.cookie = `${name}=${value};expires=${new Date(
        Date.now() + 60 * 60 * 60 * 24 * 365 * 1000
    ).toUTCString()};path=/`;
}

export function deleteCookie(name: string) {
    document.cookie = `${name}=;expires=${new Date(
        Date.now() + 60 * 60 * 60 * 24 * 365 * 1000
    ).toUTCString()};path=/`;
}

export function saveUserOnLocalStorage(user: IUserData) {
    window.localStorage.setItem("pod1umUser", JSON.stringify(user));

    if (user.token) {
        setCookie("pod1umToken", user.token);
    }

    return {
        isLoggedIn: true,
        ...user,
    };
}

export function authenticate(): IUserData {
    if (typeof window !== "undefined") {
        try {
            const user: IUserData = JSON.parse(
                localStorage.getItem("pod1umUser")
            ) as IUserData;
            if (user?.token) {
                return {
                    isLoggedIn: true,
                    ...user,
                };
            } else {
                return {
                    isLoggedIn: false,
                } as IUserData;
            }
        } catch (e: unknown) {
            console.error(e);
            Sentry.captureException({
                category: "authentication",
                message: "error on authenticate",
                data: e,
            });
        }
    }
}
