import { Snackbar } from "../snackbar/Snackbar";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { SnackbarProps } from "@mui/material/Snackbar";
import React from "react";
import { AppTextButton } from "../ui/buttons";
import { useSnackbarContext } from "./SnackbarProvider";

type ErrorSnackbarProps = {
  handleClose?: () => void;
} & SnackbarProps;

export const ErrorSnackbar = ({ message, handleClose }: ErrorSnackbarProps) => {
  const colors = useSelector((state: RootState) => state.theme.componentColors);
  const { setSnackbar } = useSnackbarContext();

  const onClose = () => {
    setSnackbar(null);
    handleClose ? handleClose() : null;
  };

  const handleAction = (
    <>
      <AppTextButton
        sx={{
          color: colors.error.onError,
        }}
        onClick={onClose}
      >
        Undo
      </AppTextButton>
    </>
  );

  return (
    <Snackbar
      onClose={onClose}
      action={handleAction}
      autoHideDuration={6000}
      open={true}
      message={message}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: colors.error.error,
          color: colors.error.onError,
        },
      }}
    />
  );
};
