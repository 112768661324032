import { ButtonProps, IconButton } from "@mui/material";

export type AppIconButtonProps = { useBorder?: boolean } & ButtonProps;

export const AppIconButton = ({
  onClick,
  className,
  sx,
  children,
  ...props
}: AppIconButtonProps) => {
  return (
    <IconButton
      className={className}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
      onClick={(e) => {
        if (onClick) {
          void onClick(e);
        }
      }}
      {...props}
    >
      {children}
    </IconButton>
  );
};
