import { logoutUser } from "../api/auth";

export const fetcher = async (
  url: string,
  data: RequestInit = {},
  retJson = true
): Promise<unknown> => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/v1/${url}`,
      data
    );

    if (
      !response.url.includes("auth") &&
      response.status === 401 &&
      typeof window != "undefined"
    ) {
      logoutUser();
      return;
    }

    if (!response.ok) {
      throw await response.json();
    }

    return retJson ? response.json() : response;
  } catch (error) {
    const errorObject = error as Error;
    console.error(errorObject);

    throw error;
  }
};
