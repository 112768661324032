import React from "react";
import { useSelector } from "react-redux";
import { Button, ButtonProps } from "@mui/material";
import { RootState } from "../../../../redux/store";

export type AppOutlinedButtonProps = ButtonProps;

export const AppOutlinedButton = ({
  children,
  type,
  onClick,
  disabled,
  startIcon,
  className,
  ...props
}: AppOutlinedButtonProps) => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <Button
      variant="outlined"
      type={type}
      className={className}
      onClick={onClick}
      startIcon={startIcon}
      disabled={disabled}
      sx={{
        borderRadius: "1.5rem",
        padding: "0 24px",
        height: "2.5rem",
        fontWeight: "500",
        textTransform: "none",
        fontSize: "0.875rem",
        color: theme.componentColors.primary.primary,
        "&:disabled": {
          opacity: 0.5,
        },
      }}
      {...props}
    >
      {children}
    </Button>
  );
};
