import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
} from "react";

type SnackbarContextType = {
  snackbar?: React.ReactNode;
  setSnackbar?: Dispatch<SetStateAction<React.ReactNode>>;
};

export const SnackbarContext = createContext<SnackbarContextType | null>(null);

export const useSnackbarContext = () => {
  const context = useContext(SnackbarContext);

  if (!context) {
    throw new Error(
      "useSnackbarContext can only be accessed within SnackbarProvider",
    );
  }

  return context;
};

export const SnackbarProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [snackbar, setSnackbar] = React.useState<React.ReactNode | null>(null);

  const setTimeoutSnackbar = () => {
    setTimeout(() => {
      setSnackbar(null);
    }, 5000);
  };

  useEffect(() => {
    setTimeoutSnackbar();
  }, [snackbar]);

  return (
    <SnackbarContext.Provider
      value={{
        snackbar,
        setSnackbar,
      }}
    >
      {children}
      {snackbar}
    </SnackbarContext.Provider>
  );
};
